import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    lessPaddingTitle: {
      paddingBottom: "2px",
    },
    lessPaddingContent: {
      paddingTop: "2px",
      paddingBottom: "2px",
    }
  }),
);

export default function ConfirmationDialogComponent(props: {
  isDialogOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title?: string;
  description?: string
}) {
  
  const classes = useStyles();

  return <Dialog
    open={props.isDialogOpen}
    onClose={() => props.onClose()}
    keepMounted
  >
    <DialogTitle className={classes.lessPaddingTitle}>
      {props.title ?? "Confirmation"}
      </DialogTitle>
    <DialogContent className={classes.lessPaddingContent}>
      <DialogContentText >
        {props.description ?? "Are you sure you want to perform this action?"}
          </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={props.onClose} color="primary">
        Cancel
        </Button>
      <Button onClick={props.onConfirm} color="primary">
        Confirm
        </Button>
    </DialogActions>
  </Dialog>
}