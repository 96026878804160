
import { createErrorToast, createSuccessToast } from "@components/ToastContainer";

export async function performAction<T>(
	adminCall: () => Promise<T>,
	failText?: string,
	reload: boolean = true,
	logException: boolean = false) {
	const onClose = () => {
		if (reload)
			window.location.reload();
	};

	try {
		const result = await adminCall();
		createSuccessToast("Success!", onClose);

		return result;
	} catch (e: unknown) {
		var error = e as Error;

		createErrorToast(failText ?? error?.message ?? "Error", onClose);
	}
}