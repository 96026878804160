import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { useState } from "react";

interface TabPanelProps {
	children?: React.ReactNode;
	tabIndex: number;
	selectedTabIndex: number;
}

function TabPanel(props: TabPanelProps) {
	return (
		<Box component="div" role="tabpanel" hidden={props.selectedTabIndex !== props.tabIndex}>
			{props.children}
		</Box>
	);
}

interface TabProps {
	label: string | React.ReactNode;
	content: React.ReactNode;
	width?: string | number;
}

interface Props {
	tabs: TabProps[];
	selectedTabIndex?: number;
	onTabChange?: (index: number) => void;
}

export default function TabsControl(props: Props) {
	const [currentlySelectedTabIndex, setCurrentlySelectedTabIndex] = useState(props.selectedTabIndex ?? 0);

	const onTabChange = (tabIndex: number) => {
		if (props.onTabChange != null) {
			props.onTabChange(tabIndex);
		}
		setCurrentlySelectedTabIndex(tabIndex);
	}

	let tabs = props.tabs || [];
	return (
		<>
			<AppBar position="static">
				<Tabs
					value={currentlySelectedTabIndex}
					onChange={(_e, index) => onTabChange(index)}
				>
					{tabs.map((t, index) => (
						<Tab label={t.label} key={index} style={{minWidth: t.width}}/>
					))}
				</Tabs>
			</AppBar>
			{tabs.map((tabProps, index) => (
				<TabPanel
					selectedTabIndex={currentlySelectedTabIndex}
					tabIndex={index}
					key={index}
				>
					{tabProps.content}
				</TabPanel>
			))}
		</>
	);
}