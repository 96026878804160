import { adminClient } from "@api/admin/AdminClient";
import CodeTextArea from "@components/admin/CodeTextArea";
import ConfirmationDialogComponent from "@components/admin/ConfirmationDialogComponent";
import TabbedTableComponent from "@components/TabbedTableComponent";
import { Header } from "@components/TableComponent";
import { Dialog, IconButton, TableCell } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import CodeIcon from '@material-ui/icons/Code';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import RemoveIcon from '@material-ui/icons/Remove';
import { AdminDelayedTaskResponse } from "@reshopper/admin-client";
import { performAction } from "@utils/actions";
import { toDanishDateFormat } from "@utils/miscellaneous";
import { useState } from "react";

export default function DelayedTasksListComponent(props: { entityId?: string }) {
	const [currentCode, setCurrentCode] = useState("");
	const [exactRequestName, setExactRequestName] = useState<string>();
	const [excludedRequestNames, setExcludedRequestNames] = useState<string[]>([]);
	const [isRemoveDialogOpen, setRemoveDialogOpen] = useState(false);
	const [currenctlySelectedDelayedTaskId, setCurrentlySeletedDelayedTaskId] = useState<string>();

	const size = 100;

	const scheduledHeaders: Array<Header<AdminDelayedTaskResponse>> = [
		{
			label: "Request name",
			databaseSortingKey: x => x.requestName
		},
		{
			databaseSortingKey: x => x.startAtUtc,
			label: "Scheduled for",
			isDefault: true
		},
		{
			databaseSortingKey: x => x.createdAtUtc,
			label: "Created",
		}
	];

	const startedHeaders = [...scheduledHeaders];
	startedHeaders.push({
		label: "Started",
		databaseSortingKey: x => x.startedAtUtc
	});

	const succeededHeaders = [...startedHeaders];
	succeededHeaders.push({
		label: "Succeeded",
		databaseSortingKey: x => x.succeededAtUtc
	});

	const failedHeaders = [...startedHeaders];
	failedHeaders.push({
		label: "Failed",
		databaseSortingKey: x => x.failedAtUtc
	});

	function onRemoveClick(delayedTaskId: string) {
		setCurrentlySeletedDelayedTaskId(delayedTaskId);
		setRemoveDialogOpen(true);
	}

	function closeRemoveDialog() {
		setCurrentlySeletedDelayedTaskId(undefined);
		setRemoveDialogOpen(false);
	}

	async function removeFromList(delayedTaskId?: string) {
		if (!delayedTaskId) {
			return;
		}
		await performAction(
			() => adminClient().adminDelayedTaskDelete(delayedTaskId),
			"Failed to remove delayed task",
			true);
	}

	const onRequestNameAdded = (requestName: string) => {
		setExactRequestName(requestName);
		setExcludedRequestNames(excludedRequestNames?.filter(x => x !== requestName));
	}

	const onRequestNameExcluded = (requestName: string) => {
		if (exactRequestName) {
			setExactRequestName(undefined);
		} else {
			setExcludedRequestNames([
				...excludedRequestNames,
				requestName
			]);
		}
	}

	const onCodeButtonClicked = (code: string) => {
		setCurrentCode(code);
	}

	function DelayedTaskRow(props: {
		task: AdminDelayedTaskResponse,
		statusDateValue?: Date,
		onRequestNameAdded: () => void,
		onRequestNameExcluded: () => void,
		onCodeButtonClicked: () => void
	}) {

		return <>
			<TableCell>
				{props.task.requestName}
				<IconButton
					size="small"
					onClick={props.onCodeButtonClicked}
					style={{
						marginLeft: 20,
						marginRight: 10
					}}>
					<CodeIcon />
				</IconButton>
				<IconButton
					size="small"
					onClick={props.onRequestNameAdded}
					style={{
						marginLeft: 10
					}}>
					<AddIcon />
				</IconButton>
				<IconButton
					size="small"
					onClick={props.onRequestNameExcluded}
					style={{
						marginLeft: 10
					}}>
					<RemoveIcon />
				</IconButton>
				<IconButton
					size="small"
					onClick={() => onRemoveClick(props.task.id)}
					style={{
						marginLeft: 10,
						cursor: "pointer"
					}}>
					<HighlightOffIcon color="error" />
				</IconButton>
			</TableCell>
			<TableCell>{toDanishDateFormat(props.task.startAtUtc, "PPpp")}</TableCell>
			<TableCell>{toDanishDateFormat(props.task.createdAtUtc, "PPpp")}</TableCell>
			{!!props.task.startedAtUtc && <TableCell>{toDanishDateFormat(props.task.startedAtUtc, "PPpp")}</TableCell>}
			{!!props.statusDateValue && <TableCell>{toDanishDateFormat(props.statusDateValue, "PPpp")}</TableCell>}
		</>;
	}

	let entityId = props.entityId;

	return <>
		<Dialog
			open={!!currentCode}
			onClose={() => setCurrentCode("")}
		>
			<CodeTextArea
				readonly
				code={currentCode}
				height={300}
				width={500}
				language="json" />
		</Dialog>
		<TabbedTableComponent<AdminDelayedTaskResponse> tabs={[
			{
				label: "Scheduled",
				table: {
					call: async (options) =>
						await adminClient().adminScheduledDelayedTasksGet({
							...options,
							exactRequestName,
							excludedRequestNames,
							entityId
						}),
					headers: scheduledHeaders,
					renderRow: data => <DelayedTaskRow
						task={data}
						onRequestNameAdded={() => onRequestNameAdded(data.requestName)}
						onRequestNameExcluded={() => onRequestNameExcluded(data.requestName)}
						onCodeButtonClicked={() => onCodeButtonClicked(data.requestJson)}
					/>,
					size,
					sortDirection: "asc",
					dependencies: [exactRequestName, excludedRequestNames]
				}
			},
			{
				label: "Succeeded",
				table: {
					call: async (options) =>
						await adminClient().adminSuccededDelayedTasksGet({
							...options,
							exactRequestName,
							excludedRequestNames,
							entityId
						}),
					headers: succeededHeaders,
					renderRow: data => <DelayedTaskRow
						task={data}
						statusDateValue={data.succeededAtUtc}
						onRequestNameAdded={() => onRequestNameAdded(data.requestName)}
						onRequestNameExcluded={() => onRequestNameExcluded(data.requestName)}
						onCodeButtonClicked={() => onCodeButtonClicked(data.requestJson)}
					/>,
					size,
					sortDirection: "asc",
					dependencies: [exactRequestName, excludedRequestNames]
				}
			},
			{
				label: "Failed",
				table: {
					call: async (options) =>
						await adminClient().adminFailedDelayedTasksGet({
							...options,
							exactRequestName,
							excludedRequestNames,
							entityId
						}),
					headers: failedHeaders,
					renderRow: data => <DelayedTaskRow
						task={data}
						statusDateValue={data.failedAtUtc}
						onRequestNameAdded={() => onRequestNameAdded(data.requestName)}
						onRequestNameExcluded={() => onRequestNameExcluded(data.requestName)}
						onCodeButtonClicked={() => onCodeButtonClicked(data.requestJson)}
					/>,
					size,
					sortDirection: "asc",
					dependencies: [exactRequestName, excludedRequestNames]
				}
			},
			{
				label: "Stuck",
				table: {
					call: async (options) =>
						await adminClient().adminStuckDelayedTasksGet({
							...options,
							exactRequestName,
							excludedRequestNames,
							entityId
						}),
					headers: scheduledHeaders,
					renderRow: data => <DelayedTaskRow
						task={data}
						onRequestNameAdded={() => onRequestNameAdded(data.requestName)}
						onRequestNameExcluded={() => onRequestNameExcluded(data.requestName)}
						onCodeButtonClicked={() => onCodeButtonClicked(data.requestJson)}
					/>,
					size,
					sortDirection: "asc",
					dependencies: [exactRequestName, excludedRequestNames]
				}
			}
		]} />
		<ConfirmationDialogComponent
			isDialogOpen={isRemoveDialogOpen}
			onClose={closeRemoveDialog}
			onConfirm={() => removeFromList(currenctlySelectedDelayedTaskId)}
			title="Confirm removing delayed task"
			description="Are you sure you want to remove delayed task?"
		/>
	</>
}

