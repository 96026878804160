import TableComponent, { Props as TableComponentProps } from "@components/TableComponent";
import TabsControl from "@components/TabsControl";
import { CircularProgress } from "@material-ui/core";
import { useRef, useState } from "react";

type TabProps<TData> = {
    label: string,
    table: TableComponentProps<TData>,
    width?: string | number
}

export default function TabbedTableComponent<TData = unknown>(props: {
    tabs: TabProps<TData>[]
}) {
    const counts = useRef(props.tabs.map(() => undefined! as number));
    const [countsState, setCountsState] = useState(counts.current);

    return <TabsControl tabs={props.tabs.map((tab, index) => ({
        label: <span>
            {tab.label} 
            <span style={{opacity: 0.5, display: 'inline-block', marginLeft: 12}}>
                {countsState[index] === undefined ?
                    <CircularProgress style={{
                        color: "white",
                        width: 12,
                        height: 12
                    }} /> :
                    countsState[index]}
            </span>
        </span>,
        content: <TableComponent<TData>
            {...tab.table}
            onDataFetched={(items, count) => {
                tab.table.onDataFetched && tab.table.onDataFetched(items, count);
                counts.current[index] = count;
                setCountsState([...counts.current]);
            }}
        />,
        width: tab.width
    }))} />
}